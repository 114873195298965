(function () {
    angular.module('informaApp')
        .component('multiList', {
            templateUrl: 'components/multi-select/template.html',
            controller: ['$timeout', '$element', UserSelect],
            bindings: {
                source: '<',
                orderBy: '<',
                optionIf: '<',
                optionBind: '<',
                options: '<',
                onReady: '<'
            }
        });

    function UserSelect($timeout, $element) {
        var vm = this;

        this.$onInit = function () {

        };

        vm.onOptionsAdded = function () {
            $timeout(function () {
                var element = $element.find('.multiselect');

                makeMultiSelect(element, vm.options, vm.onReady);
            });
        }
    }

    function makeMultiSelect(element, options, onReady) {
        var selectOptions = {
            includeSelectAllOption: true,
            enableFiltering: true,
            enableCaseInsensitiveFiltering: true,
            numberDisplayed: 0,
            allSelectedText: 'All',
            nonSelectedText: 'All'
        };

        var newOptions = _.merge({}, selectOptions, options);

        var multiSelect = element.multiselect('destroy')
            .multiselect(newOptions);

        if (onReady) {
            onReady(multiSelect);
        }
    }
})();